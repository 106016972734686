<template>
	<div class="statistics">
		<div class="wrapper">
			<div class="screen">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>数据统计</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="statistics-screen">
					<span class="title">筛选：</span>
					<el-date-picker v-model="timeValue" @change="chooseDate" type="datetimerange"
						:picker-options="pickerOptions" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
						range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right">
					</el-date-picker>
					<el-button type="primary" @click="handleSearch" class="main-btn">查询</el-button>
					<el-button type="primary" @click="handleDownload" class="main-btn">导出表格</el-button>
				</div>
			</div>
			<div class="classify">
				<el-row :gutter="30">
					<el-col :span="6">
						<div class="classify-item" @click="labelChange(1)">
							<span>审核人员</span><em>{{verifyUserTotal}}<i>人</i></em>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="classify-item" @click="labelChange(2)">
							<span>录入人员</span><em>{{editUserTotal}}<i>人</i></em>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="classify-item" @click="labelChange(3)">
							<span>审核通过数</span><em>{{passedTotal}}<i>条</i></em>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="classify-item" @click="labelChange(4)">
							<span>审核未通过数</span><em>{{unPassedTotal}}<i>条</i></em>
						</div>
					</el-col>
				</el-row>
			</div>
			<el-row :gutter="30">
				<el-col :span="16">
					<user-list v-if="index == 1" :tableData="verifyUserList" :total="verifyUserTotal" :type="1"
						:pagesize="query.pagesize" @pageChange="handleVerifyUserList"></user-list>
					<user-list v-if="index == 2" :tableData="editUserList" :total="editUserTotal" :type="2"
						:pagesize="query.pagesize" @pageChange="handleEditUserList"></user-list>
					<el-input v-if="index == 3" placeholder="请输入关键字搜索" v-model="keyword" clearable class="input-with-select">
						<el-button slot="append" @click="getPassedList(1)" icon="el-icon-search" class="main-btn"></el-button>
					</el-input>
					<verify-list v-if="index == 3" :tableData="passedList" :total="passedTotal"
						:pagesize="query.pagesize" @pageChange="getPassedList" @onSortChange="handleSortChange">
					</verify-list>
					<verify-list v-if="index == 4" :tableData="unPassedList" :total="unPassedTotal"
						:pagesize="query.pagesize" @pageChange="getUnPassedList" @onSortChange="handleSortChange">
					</verify-list>
				</el-col>
				<el-col :span="8">
					<div class="entry-clerk">
						<div class="entry-clerk-title">
							录入统计
							<div class="sort" @click="sortClick">
								<span>排序</span>
								<div class="caret">
									<i class="el-icon-caret-top" :class="{active:count%2==0}"></i>
									<i class="el-icon-caret-bottom" :class="{active:count%2!=0}"></i>
								</div>
							</div>
						</div>
						<div class="choose">
							<i @click="lastDay()" class="el-icon-d-arrow-left"></i>
							<span>{{userInputDate}}</span>
							<i @click="nextDay()" class="el-icon-d-arrow-right"></i>
						</div>
						<ul class="entry-list">
							<li v-for="item in entryList" :key="item.id">
								<span>{{item.name}}</span><em><i>{{item.num}}</i>条</em>
							</li>
							<li class="total"><span>总计:</span><em><i>{{total}}</i>条</em>
							</li>
						</ul>
					</div>
				</el-col>
			</el-row>

		</div>
	</div>
</template>

<script>
	import userList from '../components/userList'
	import verifyList from "@/views/components/verifyList";

    export default {
		name: "enterStatistics",
		components: {
			userList,
			verifyList
		},
		data() {
			return {
                keyword: '',
				total: 0,
				count: 1,
				userInputDate: '',
				entryList: [],
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				timeValue: '',
				verifyUserList: [],
				verifyUserTotal: 0,
				editUserList: [],
				editUserTotal: 0,
				passedList: [],
				unPassedList: [],
				query: {
					page: 1,
					pagesize: 10,
					startDate: '',
					endDate: '',
					status: '',
				},
				index: 1,
				passedTotal: 0,
				unPassedTotal: 0
			};
		},
		created() {
			this.init()
			this.userInputDate = this.common.today()
			this.getUserInputList()
		},
		mounted() {},
		methods: {
			handleSortChange(e) {
				console.log(e)
				let sort = ''
				if (e.prop == 'modifyTime') {
					sort = 'modify_time'
				}
				if (e.order == 'descending') {
					sort = '-' + sort
				} else {
					sort = '+' + sort
				}
				this.query.sort = sort
				this.screenClick()
			},
			lastDay() {
				this.userInputDate = this.common.dayOffset(this.userInputDate, -1)
				this.getUserInputList()
			},
			nextDay() {
				let next = this.common.dayOffset(this.userInputDate, 1)
				if (new Date(next) >= new Date()) {
					return
				}
				this.userInputDate = next
				this.getUserInputList()
			},
			getUserInputList() {
				this.$http.fetchGet("/api/admin/user/input", {
						date: this.userInputDate
					})
					.then((res) => {
						if (res.data.code == 0) {
							this.entryList = res.data.data

							this.total = this.entryList.reduce((sum, item) => {
								return sum += item.num
							}, 0)

							this.handleSortClick()
						}
					});
			},
			sortClick() {
				this.count++
				this.handleSortClick()
			},
			handleSortClick() {
				if (this.count % 2 == 0) {
					this.entryList.sort((a, b) => {
						return a.num - b.num;
					})
				} else {
					this.entryList.sort((a, b) => {
						return b.num - a.num;
					})
				}
			},
			init() {
				this.handleVerifyUserList(1)
				this.handleEditUserList(1)
				this.getPassedList(1)
				this.getUnPassedList(1)
			},
			handleVerifyUserList(page) {
				Promise.all([this.getVerifyUserList(page)]).then(resArr => {
					let res = resArr[0]
					let data = res.data.data
					this.verifyUserList = data.list
					this.verifyUserTotal = data.total ? data.total : 0
				})
			},
			handleEditUserList(page) {
				Promise.all([this.getEditUserList(page)]).then(resArr => {
					let res = resArr[0]
					let data = res.data.data
					this.editUserList = data.list
					this.editUserTotal = data.total ? data.total : 0
				})
			},
			handleSearch() {
				this.init()
			},
			chooseDate() {
				if (this.timeValue.length < 2) {
					this.query.startDate = ''
					this.query.endDate = ''
				}
				this.query.startDate = this.timeValue[0]
				this.query.endDate = this.timeValue[1]
			},
			labelChange(index) {
				this.index = index
				this.query.page = 1
				this.screenClick()
			},
			screenClick() {
				let index = this.index
				if (index === 1) {
					this.handleVerifyUserList(this.query.page)
				}
				if (index === 2) {
					this.handleEditUserList(this.query.page)
				}
				if (index === 3) {
					this.getPassedList(this.query.page)
				}
				if (index === 4) {
					this.getUnPassedList(this.query.page)
				}
			},
			getStateInfo() {
				this.$http.fetchGet("/api/admin/stats/info", this.query).then((res) => {
					if (res.data.code == 0) {
						let stateInfo = res.data.data
						this.passedTotal = stateInfo.passedTotal
						this.unPassedTotal = stateInfo.unPassedTotal
					}
				});
			},
			getVerifyUserList(page, pagesize) {
				return new Promise((resolve) => {
					let query = Object.assign({}, this.query)
					query.page = page
					if (pagesize) {
						query.pagesize = pagesize
					}
					this.$http.fetchGet("/api/admin/stats/verify/user/list", query).then((res) => {
						if (res.data.code == 0) {
							resolve(res)
						}
					});
				})
			},
			getEditUserList(page, pagesize) {
				return new Promise((resolve) => {
					let query = Object.assign({}, this.query)
					query.page = page
					if (pagesize) {
						query.pagesize = pagesize
					}
					this.$http.fetchGet("/api/admin/stats/edit/user/list", query).then((res) => {
						if (res.data.code == 0) {
							resolve(res)
						}
					});
				})
			},
			getPassedList(page) {
                let query = Object.assign({}, this.query)
				query.page = page
				query.status = 10
                query.keyword = this.keyword
				this.$http.fetchGet("/api/admin/product/modify/list", query).then((res) => {
					if (res.data.code == 0) {
						let data = res.data.data
						this.passedList = data.list
						this.passedTotal = data.total ? data.total : 0
					}
				});
			},
			getUnPassedList(page) {
				this.query.page = page
				this.query.status = -10
				this.$http.fetchGet("/api/admin/product/modify/list", this.query).then((res) => {
					if (res.data.code == 0) {
						let data = res.data.data
						this.unPassedList = data.list
						this.unPassedTotal = data.total ? data.total : 0
					}
				});
			},
			editClick(item) {
				this.$router.push("/sparesDetails?id=" + item.id);
			},
			deleteClick() {
				this.$confirm("确定要永久删除该行备品吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$message({
							type: "success",
							message: "删除成功!",
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
			},
			handleSort(e) {
				if (e.order == "ascending") {
					this.query.sort = "+" + e.prop
				} else if (e.order == "descending") {
					this.query.sort = "-" + e.prop
				} else {
					this.query.sort = ""
				}
				// this.query.page = 1
				this.getList()
			},
			handleDownload() {
				this.downloadLoading = true
				Promise.all([this.getVerifyUserList(1, 10000),
					this.getEditUserList(1, 10000)
				]).then(resArr => {
					let verifyUserList = resArr[0].data.data.list
					let editUserList = resArr[1].data.data.list
					let list = []
					if (this.query.startDate && this.query.endDate) {
						list.push({
							val1: '',
							val2: '',
							val3: '',
							val4: '日期：' + this.query.startDate + ' - ' + this.query.endDate
						})
					} else {
						list.push({
							val1: '',
							val2: '',
							val3: '',
							val4: '日期：所有记录'
						})
					}
					list.push({
						val1: '审核人员',
						val2: '录入人员',
						val3: '总审核通过数',
						val4: '总未审核通过数'
					})
					list.push({
						val1: this.verifyUserTotal,
						val2: this.editUserTotal,
						val3: this.passedTotal,
						val4: this.unPassedTotal
					})
					list.push({
						val1: '',
						val2: '',
						val3: '',
						val4: ''
					})
					list.push({
						val1: '审核人员列表',
						val2: '',
						val3: '',
						val4: ''
					})
					list.push({
						val1: '姓名',
						val2: '人员类型',
						val3: '审核通过',
						val4: '审核未通过'
					})
					verifyUserList.forEach(e => {
						list.push({
							val1: e.name,
							val2: '审核员',
							val3: e.passedNum,
							val4: e.unPassedNum
						})
					})
					list.push({
						val1: '',
						val2: '',
						val3: '',
						val4: ''
					})
					list.push({
						val1: '录入人员列表',
						val2: '',
						val3: '',
						val4: ''
					})
					list.push({
						val1: '姓名',
						val2: '人员类型',
						val3: '审核通过',
						val4: '审核未通过'
					})
					editUserList.forEach(e => {
						list.push({
							val1: e.name,
							val2: '录入员',
							val3: e.passedNum,
							val4: e.unPassedNum
						})
					})
					import('@/vendor/Export2Excel').then(excel => {
						const tHeader = ['', '', '', '']
						const filterVal = ['val1', 'val2', 'val3', 'val4']
						const data = this.formatJson(filterVal, list)
						excel.export_json_to_excel({
							header: tHeader,
							data,
							filename: "统计报表",
							autoWidth: false,
							bookType: 'xlsx'
						})
						this.downloadLoading = false
					})
				})
			},
			formatJson(filterVal, jsonData) {
				return jsonData.map(v => filterVal.map(j => {
					if (j === 'state' && v[j]) {
						return this.stateFormat({
							state: v[j]
						})
					} else {
						return v[j]
					}
				}))
			}
		},
	};
</script>

<style lang="scss">
	@import '~@/assets/css/statistics.scss';
</style>
