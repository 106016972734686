<template>
    <div>
        <el-table :data="tableData" class="main-table" header-row-class-name="table-header"
                  cell-class-name="table-hover" :row-class-name="tableRowClassName">
            <el-table-column label="序号" type="index" :index="indexMethod" width="80" align="center">
            </el-table-column>
            <el-table-column prop="name" label="姓名" align="center"></el-table-column>
            <el-table-column prop="status" label="人员类型" align="center">
                <template slot-scope="">
                    <el-tag type="success" plain disable-transitions v-if="type === 1">审核员</el-tag>
                    <el-tag disable-transitions v-if="type === 2">录入员</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="数据统计" align="center" class-name="table-operate">
                <template slot-scope="{row}">
                    <div class="data">
                        <ul>
                            <li><span>审核通过</span><em>{{row.passedNum}}</em></li>
                            <li><span>审核未通过</span><em>{{row.unPassedNum}}</em></li>
                        </ul>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next" :total="this.total" :current-page.sync="query.page"
                       :page-size="pagesize" @current-change="pageChange">
        </el-pagination>

    </div>
</template>

<script>
export default {
    name: "userList",
    props: ['tableData', 'total', 'type', 'pagesize'],
    data() {
        return {
            query: {
                page: 1,
            }
        }
    },
    methods: {
        pageChange(page){
            this.query.page = page
            this.$emit('pageChange', page)
        },
        tableRowClassName({
                              rowIndex
                          }) {
            if (rowIndex % 2 == 0) {
                return "";
            } else {
                return "active-row";
            }
        },
        indexMethod(e){
            return (this.query.page - 1)*this.pagesize + e + 1
        }
    }
}
</script>

<style scoped>

</style>
